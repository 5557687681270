

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
    
}



strong{
    color: #006494;
    
}

body{
    font-family: 'Dosis',
    sans-serif;
    font-family: 'Lato',
    sans-serif;
    font-family: 'News Cycle',
    sans-serif;
    
}

body::-webkit-scrollbar {
    display: none;
}

.intro-screen{
    display: flex;
    color: black;
    
}

.intro-screen strong{
    color: blue;
}

.intro-text, .intro-pic{
    height: 99vh;
    width: 50vw;
   
    display: flex;
}

.intro-text-centering-container{
    margin: auto auto;
   
}

.intro-text h1{
    font-size: 72px;
    
}

.intro-text strong{
    color: #247BA0;
}

.job-description{
    margin-top: 3em
}

.intro-pic img{
    
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.about-container{
    display: flex;
}

.about-me-text-container{
    margin: 200px 200px auto;
    
}

@media only screen and (max-width: 1265px) {
    .about-me-text-container {
            margin: 100px 100px auto;
    
        }
}

@media only screen and (max-width: 945px) {
    .about-me-text-container {
        margin: 80px 80px auto;
        margin-top: 100PX;

    }
}





 .about-me{
    
    height: 99vh;
    width: 50vw;
  
}

.icons-container{
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    
    justify-content: space-evenly;
    
    margin-top: 25%;
    
    

   
}

.animation-effect-container{
    transform: scale(1);
            animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        
    }

    70% {
        transform: scale(1);
        
    }

    100% {
        transform: scale(0.95);
        
    }
}







.skills{
    height: 99vh;
    width: 50vw;
    background-color: #ACC8E5;
    
    
    
}

.icon-container{
    height: 50px;
    width: 50px;
    background-color: #fff;
    /* padding: 2em; */
    margin: 1em;
   
    border-radius: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    

}

.icon-container img{
  
    height: 30px;
    width: 30px;
}


.codewars-badge-container{
    display: flex;
    justify-content: flex-end;
}

.project-container{
    display: flex;
    
}



.project-info {
    position: relative;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: 10px;
    transform-style: preserve-3d;
    transition: all 0.8s ease

}

.project-view{
    height: 99vh;
    width: 50vw;
    color: #fff;
    
}

.project-text{
    
    margin: auto;
    width: 50%;
    text-align: center;
    padding-top: 30%;
}

.project-card{
  
    background-color: #006494;
    padding-top: 20%;
        width: 100%;
        height: 100%;
}

.project-card-left{
    
    background-color: #247BA0;
    padding-top: 20%;
    width: 100%;
        height: 100%;
    
}

.project-card img{
    border: none;
    
    padding: 5px;
    width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
}

.project-card-left img{
    border: none;
    
    padding:  5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
       
}

.project-card a {
    background-color: #fff;
    border-radius: 5px;
    color: #006494;
    padding: 5px;
    margin-left: 50%;
    text-decoration: none;
    

    
}

.project-card a:visited{
    text-decoration: none;
}

.project-card-left a{
    background-color: #fff;
        border-radius: 5px;
        color: #247BA0;
        padding: 5px;
        margin-left: 50%;
        text-decoration: none;
}






@media only screen and (max-width: 950px) {
    .intro-screen{
        flex-direction: column;
        width: 100vw;
    }

    .intro-text, .intro-pic{
        width: 100vw;
        flex-direction: column;
    }

    

   

    .about-container{
        flex-direction: column;
    }

    /* .about-me-text-container{
        margin: 10px;
    } */

        

    .skills, .about-me{
        width: 100vw;
        flex-direction: column;
        
        
    }

    .about-me{
        padding: 0;
        
        
    }

    

    .project-container {
        flex-direction: column;
    }
            
    .intro-text, .project-view, .project-card, .project-card-left, .project-info{
width: 100vw;
height: 80vh;
    flex-direction: column;
    }    

    .icon-container{
        margin: 0;
    }

    .project-info{
        display: none;
    }

    .project-card, .project-card-left{
        padding-top: 10px;
    }

        .project-card a,
        .project-card-left a{
            margin-left: 45%;
        }
}



   







